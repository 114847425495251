<template>
  <div class="wrapper">
    <div class="container">
      <div class="section__line">
        <div class="link__top">
          <a class="link__back" @click="$router.push('/')">
            <span class="link__back--img">
              <img src="../assets/img/arrow-back.svg" />
            </span>
            Назад
          </a>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <div class="section__line">
            <div class="section__bg section--bank__info section--bank__data">
              <div class="section__title--block">
                <div class="section__title">Редактирование личных данных организации</div>
              </div>
              <form @submit.prevent="updateProfile">
                <div class="row row__col">
                  <div class="col-lg-12 col__padding">
                    <label class="form__label">
                      <div class="form__label--title">Название организации</div>
                      <div class="form__label--input">
                        <input
                          type="text"
                          name=""
                          v-model="user.organization.name"
                          placeholder="Название  "
                        />
                      </div>
                    </label>
                  </div>
                  <div class="col-lg-12 col__padding">
                    <label class="form__label">
                      <div class="form__label--title">
                        Название организации (на казахском)
                      </div>
                      <div class="form__label--input">
                        <input
                          type="text"
                          name=""
                          v-model="user.organization.name_kz"
                          placeholder="Название  "
                        />
                      </div>
                    </label>
                  </div>
                  <div class="col-lg-6 col__padding">
                    <label class="form__label">
                      <div class="form__label--title">Телефон</div>
                      <div class="form__label--input">
                        <!-- <input
                          type="text"
                          name=""
                          v-model="user.organization.phone"
                          placeholder="Телефон"
                        /> -->
												<input type="text" v-mask="{mask: '+7 (999) 999 99 99'}"  v-model="user.organization.phone" />
											</div>
                    </label>
                  </div>
                  <div class="col-lg-6 col__padding">
                    <label class="form__label">
                      <div class="form__label--title">Е-mail</div>
                      <div class="form__label--input">
                        <input type="text" required name="" v-model="user.organization.email" />
                      </div>
                    </label>
                  </div>

                  <div class="col-lg-6 col__padding">
                    <label class="form__label">
                      <div class="form__label--title">БИК</div>
                      <div class="form__label--input">
                        <input
                          type="text"
                          name=""
                          v-model="user.organization.bik"
                          placeholder="БИК"
                        />
                      </div>
                    </label>
                  </div>

                  <div class="col-lg-6 col__padding">
                    <label class="form__label">
                      <div class="form__label--title">ИИК</div>
                      <div class="form__label--input">
                        <input
                          type="text"
                          name=""
                          v-model="user.organization.iik"
                          placeholder="ИИК"
                        />
                      </div>
                    </label>
                  </div>

                  <div class="col-lg-6 col__padding">
                    <label class="form__label">
                      <div class="form__label--title">Сайт</div>
                      <div class="form__label--input">
                        <input
                          type="text"
                          name=""
                          v-model="user.organization.website"
                          placeholder="Сайт"
                          value="kaspibank.kz"
                        />
                      </div>
                    </label>
                  </div>
                  <div class="col-lg-12 col__padding">
                    <label class="form__label">
                      <div class="form__label--title">Адрес</div>
                      <div class="form__label--input">
                        <input
                          type="text"
                          name=""
                          placeholder="Адрес"
                          v-model="user.organization.address"
                        />
                      </div>
                    </label>
                  </div>
                  <div class="col-lg-12 col__padding">
                    <label class="form__label">
                      <div class="form__label--title">Адрес (на казахском)</div>
                      <div class="form__label--input">
                        <input
                          type="text"
                          name=""
                          placeholder="Адрес (на казахском)"
                          v-model="user.organization.address_kz"
                        />
                      </div>
                    </label>
                  </div>

                  <div class="col-lg-12">
                    <v-file-input
                      v-model="images"
                      placeholder=""
                      label="Выберите файл"
                      multiple
                      prepend-icon="mdi-paperclip"
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip small label color="primary">
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </div>

                  <div class="col-lg-4" v-for="file in user.files" :key="file.id">
                    <div
                      class="mr-2 pointer"
                      @click="downloadOrganizationFile(file.id, file.fileName)"
                    >
                      <i class="mdi mdi-file-document-multiple-outline"></i>
                      <p>{{ file.fileName }}</p>
                    </div>
										<span   class="mb-0 pointer"  @click="deleteFile(file.id)">Удалить</span>
                  </div>

                  <div class="col-lg-12 col__padding">
                    <button type="submit" class="form__button form__button--margin">
                      <svg
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.68 7.34667C13.7849 7.23411 13.842 7.08524 13.8393 6.93142C13.8366 6.7776 13.7742 6.63083 13.6655 6.52205C13.5567 6.41326 13.4099 6.35095 13.2561 6.34824C13.1023 6.34552 12.9534 6.40262 12.8408 6.5075L8.11458 11.2337L6.15917 9.27833C6.04661 9.17345 5.89774 9.11635 5.74392 9.11907C5.5901 9.12178 5.44334 9.1841 5.33455 9.29288C5.22576 9.40167 5.16345 9.54843 5.16074 9.70225C5.15802 9.85607 5.21512 10.0049 5.32 10.1175L7.695 12.4925C7.80633 12.6037 7.95724 12.6661 8.11458 12.6661C8.27193 12.6661 8.42284 12.6037 8.53417 12.4925L13.68 7.34667V7.34667Z"
                          fill="white"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M9.49996 0.791666C4.69058 0.791666 0.791626 4.69062 0.791626 9.5C0.791626 14.3094 4.69058 18.2083 9.49996 18.2083C14.3093 18.2083 18.2083 14.3094 18.2083 9.5C18.2083 4.69062 14.3093 0.791666 9.49996 0.791666ZM1.97913 9.5C1.97913 7.50535 2.7715 5.5924 4.18193 4.18197C5.59236 2.77154 7.50531 1.97917 9.49996 1.97917C11.4946 1.97917 13.4076 2.77154 14.818 4.18197C16.2284 5.5924 17.0208 7.50535 17.0208 9.5C17.0208 11.4946 16.2284 13.4076 14.818 14.818C13.4076 16.2285 11.4946 17.0208 9.49996 17.0208C7.50531 17.0208 5.59236 16.2285 4.18193 14.818C2.7715 13.4076 1.97913 11.4946 1.97913 9.5V9.5Z"
                          fill="white"
                        />
                      </svg>
                      Сохранить
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import Vue from 'vue';

const VueInputMask = require('vue-inputmask').default
Vue.use(VueInputMask)
export default {
  components: {},
  data() {
    return {
      openInfo: false,
      user: {
        organization: {
          website: "",
          address: "",
        },
      },
      images: [],
    };
  },
  created() {},

  methods: {
    ...mapActions(["USER_DATA", "REDIRECT_FALSE"]),
    downloadOrganizationFile(id, name) {
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + "organization/file?id=" + id,
        data: this.sobs,
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    hideInfo() {
      this.openInfo = !this.openInfo;
    },
    getProfile() {
      this.REDIRECT_FALSE();
      this.USER_DATA();
      this.user = this.GET_USER_DATA;

      this.user.organization.phone =
        this.user.organization.phone != "null" ? this.user.organization.phone : "";
      this.user.organization.bik =
        this.user.organization.bik != "null" ? this.user.organization.bik : "";
      this.user.organization.iik =
        this.user.organization.iik != "null" ? this.user.organization.iik : "";
      this.user.organization.address =
        this.user.organization.address != "null" ? this.user.organization.address : "";
      this.user.organization.website =
        this.user.organization.website != "null" ? this.user.organization.website : "";

      this.user.organization.name_kz =
        this.user.organization.name_kz != "null"
          ? this.user.organization.name_kz
          : this.user.organization.name;

      this.user.organization.address_kz =
        this.user.organization.address_kz != "null"
          ? this.user.organization.address_kz
          : this.user.organization.address;
    },
    updateProfile() {
      let organizationForm = new FormData();
      organizationForm.append("name", this.user.organization.name);
      organizationForm.append("name_kz", this.user.organization.name_kz);
      organizationForm.append("phone", this.user.organization.phone);
      organizationForm.append("email", this.user.organization.email);
      organizationForm.append("website", this.user.organization.website);
      organizationForm.append("address", this.user.organization.address);
      organizationForm.append("address_kz", this.user.organization.address_kz);
      organizationForm.append("bik", this.user.organization.bik);
      organizationForm.append("iik", this.user.organization.iik);
      organizationForm.append("chairman", this.user.organization.chairman);
      organizationForm.append("board_directors", this.user.organization.board_directors);

      for (var i = 0; i < this.images.length; i++) {
        organizationForm.append("documents[]", this.images[i]);
      }
      this.$axios
        .post(this.$API_URL + this.$API_VERSION + "user/organization", organizationForm, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.REDIRECT_FALSE();
          this.USER_DATA();

          this.$toast.open({
            message: response.data.message,
            type: "success",
            position: "bottom",
            duration: 4000,
            queue: true,
          });

          this.$router.push("/");
        })
        .catch((error) => {
          let errors = error.response.data.errors;
          console.log(errors);
          for (let variable in errors) {
            this.message = errors[variable][0];

            this.$toast.open({
              message: errors[variable][0],
              type: "error",
              position: "bottom",
              duration: 4000,
              queue: true,
            });
          }
        });
    },
    getOrganization() {
      //   this.$axios
      //     .post(
      //       this.$API_URL + this.$API_VERSION + "admin/organization",
      //       organizationForm,
      //       {
      //         headers: {
      //           Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
      //           "Content-Type": "multipart/form-data",
      //         },
      //       }
      //     )
      //     .then((response) => {})
      //     .catch((error) => {});
    },
		deleteFile(id) {
      this.$axios({
        method: "delete",
        url: this.$API_URL + this.$API_VERSION + "user/organization/delete/file?id=" + id,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {	
					this.REDIRECT_FALSE();
          this.USER_DATA();

          this.$toast.open({
            message: response.data.message,
            type: "success",
            position: "bottom",
            duration: 4000,
            queue: true,
          });

          this.$router.push("/");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    if (!this.$route.params.id) {
      this.getProfile();
    } else {
      this.getOrganization();
    }
  },
  watch: {},
  computed: {
    ...mapGetters(["GET_TOKEN", "GET_USER_DATA"]),
  },
};
</script>
